<template>
  <div class="page1">
    <div class="content">
      <div
        class="searchWrapper"
        @keydown.enter.prevent="
          pageNum = 1;
          searchData();
        "
      >
        <el-form :inline="true" ref="form" :model="listQuery" label-position="right">
          <div class="search_box_title">{{ $t("searchModule.Query_Table") }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="资讯ID：">
                <el-input
                  v-model="listQuery.informationId"
                  placeholder="请输入准确信息"
                  clearable
                />
              </el-form-item>
              <el-form-item label="标题：">
                <el-input v-model="listQuery.title" clearable placeholder="请输入文章标题关键词" />
              </el-form-item>
              <el-form-item label="是否置顶：">
                <el-select v-model="listQuery.stickType" placeholder="全部" clearable>
                  <el-option label="全部" value="">全部</el-option>
                  <el-option label="是" :value="1">是</el-option>
                  <el-option label="否" :value="0">否</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分类：">
                <el-cascader
                  v-model="listQuery.categoryIdList"
                  :options="treeData"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'categoryId',
                    label: 'nodeName',
                    multiple: true,
                  }"
                  collapse-tags
                  clearable
                  placeholder="全部"
                />
              </el-form-item>
              <el-form-item label="状态：">
                <el-select
                  v-model="listQuery.statusList"
                  placeholder="全部"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option label="待发布" :value="1">待发布</el-option>
                  <el-option label="已上架" :value="2">已上架</el-option>
                  <el-option label="已下架" :value="3">已下架</el-option>
                  <el-option label="已保存" :value="0">已保存</el-option>
                  <el-option label="已封存" :value="5">已封存</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="区域：">
                <el-cascader
                  :options="cities"
                  :props="props"
                  :show-all-levels="false"
                  v-model="listQuery.releaseCityIds"
                  placeholder="全部"
                  collapse-tags
                  clearable
                />
              </el-form-item>
              <el-form-item label="发布渠道：">
                <el-cascader
                  :options="[
                    { label: 'H5', value: 1 },
                    { label: '微信小程序', value: 2 },
                    { label: '支付宝', value: 4 },
                  ]"
                  :props="{ expandTrigger: 'hover', multiple: true }"
                  :show-all-levels="false"
                  v-model="listQuery.releaseTypeList"
                  placeholder="全部"
                  collapse-tags
                  clearable
                />
              </el-form-item>
              <br />
              <el-form-item label="最后修改时间：">
                <el-date-picker
                  v-model="listQuery.time"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  @change="getTime"
                />
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.query']"
                type="primary"
                :loading="listLoading"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t("button.search") }}</AuthorityComponent
              >
              <el-button @click="onReset" type="info" icon="el-icon-delete">{{
                $t("button.reset")
              }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <AuthorityComponent
                ComponentName="el-button"
                :permission="['button.create']"
                type="primary"
                icon="el-icon-plus"
                @click="onAdd"
                >创建资讯</AuthorityComponent
              >
              <el-button
                v-if="$route.meta.authority.button.setting"
                @click="addSeting"
                type="info"
                icon="el-icon-setting"
                >设置
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          v-loading="listLoading"
          :data="tableList"
          :default-sort="{ prop: 'update_time', order: 'descending' }"
          :header-cell-style="{ background: '#f7f7f7' }"
          border
          fit
          highlight-current-row
          @sort-change="sortChange"
        >
          <el-table-column :label="$t('searchModule.Information_ID')" align="center">
            <template slot-scope="scope">
              <span v-clipboard:copy="scope.row.informationId" v-clipboard:success="onCopy">{{
                informationIdFormat(scope.row.informationId)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.title')"
            header-align="center"
            show-overflow-tooltip
            min-width="150px"
          >
            <template slot-scope="scope">
              <img v-if="scope.row.isStick == 1" :src="infoTopImage" class="infoTop" alt="" />
              <template v-if="scope.row.infoPreviewImgList">
                <el-popover
                  placement="left"
                  popper-class="poperStyle"
                  width="259"
                  trigger="hover"
                  v-if="scope.row.infoPreviewImgList[0]"
                >
                  <img
                    :src="scope.row.infoPreviewImgList[0]"
                    style="width: 235px; height: 100px"
                    alt=""
                  />
                  <img
                    slot="reference"
                    :src="scope.row.infoPreviewImgList[0]"
                    style="width: 30px; height: 30px; vertical-align: middle"
                    alt=""
                  />
                </el-popover>
              </template>
              <span>{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.classification')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.infoCateFullName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.region')"
            align="center"
            show-overflow-tooltip
            min-width="150px"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.publishCityStr }}</span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            :label="$t('searchModule.Release_channel')"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.releaseTypeStr }}</span>
            </template>
          </el-table-column>
          <el-table-column label="阅读数" prop="read_number" align="center" sortable="custom">
            <template slot-scope="scope">
              <el-tooltip :content="getReadTooltip(scope.row)" placement="top">
                <span>{{ scope.row.readNumber || 0 }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="点赞数" prop="point_number" align="center" sortable="custom">
            <template slot-scope="scope">
              <el-tooltip :content="getPointTooltip(scope.row)" placement="top">
                <span>{{ scope.row.pointNumber || 0 }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.state')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.status | statusFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column label="定时计划" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                  定时上架时间: {{ scope.row.upperTime }} <br />
                  定时下架时间: {{ scope.row.lowerTime }}
                </div>
                <i class="el-icon-date-custom" style="color: #0f6eff"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.last_Operator')" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.updateUserName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.last_Change_time')"
            min-width="120px"
            align="center"
            prop="update_time"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.updateTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="left"
            width="80"
            class-name="small-padding fixed-width"
          >
            <!--1待上架2已上架3已下架-->
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">预览</el-dropdown-item>
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.detail']"
                    command="2"
                    >详情</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.release']"
                    v-if="scope.row.status === 0"
                    command="3"
                    >发布</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.lowerShelf']"
                    v-if="scope.row.status === 1 || scope.row.status === 2"
                    command="4"
                    >下架</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    v-if="
                      scope.row.status === 0 || scope.row.status === 1 || scope.row.status === 3
                    "
                    command="5"
                    >编辑</AuthorityComponent
                  >
                  <AuthorityComponent
                    ComponentName="el-dropdown-item"
                    :permission="['button.delete']"
                    v-if="scope.row.status === 0 || scope.row.status === 1"
                    command="6"
                    >删除</AuthorityComponent
                  >
                  <AuthorityComponent
                    v-if="scope.row.status === 3"
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    command="3"
                    >上架</AuthorityComponent
                  >
                  <AuthorityComponent
                    v-if="scope.row.status === 3"
                    ComponentName="el-dropdown-item"
                    :permission="['button.edit']"
                    command="7"
                    >封存</AuthorityComponent
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
const releaseTypeOptions = [
  { value: "1", display_name: "APP" },
  { value: "2", display_name: "公众号" },
  { value: "3", display_name: "小程序" },
];

const releaseTypeKeyValue = releaseTypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.display_name;
  return acc;
}, {});

const TypeOptions = [
  { value: "1", display_name: "汽车" },
  { value: "2", display_name: "新闻" },
  { value: "3", display_name: "热议" },
  { value: "4", display_name: "福利" },
];
const calendarTypeKeyValue = TypeOptions.reduce((acc, cur) => {
  acc[cur.value] = cur.display_name;
  return acc;
}, {});
export default {
  name: "information",
  filters: {
    statusFilter(status) {
      const statusMap = ["已保存", "待发布", "已上架", "已下架", "已删除", "已封存"];
      return statusMap[status];
    },
    releaseType(type) {
      return releaseTypeKeyValue[type];
    },
    typeFilter(type) {
      return calendarTypeKeyValue[type];
    },
    stickTypeFilter(type) {
      const stickTypeOptions = {
        1: "是",
        2: "否",
      };
      return stickTypeOptions[type];
    },
    // 时间过滤器
    timeFilter(timestamp) {
      if (!timestamp) {
        return;
      }
      return this.$moment(timestamp).format("YYYY-MM-DD HH:mm");
    },
  },
  // components: { Pagination },
  data() {
    return {
      showBtn: true,
      total: 0,
      tableList: [],
      detailFormVisible: false,
      listLoading: false,
      list: null,
      infoTopImage: require("./infoTop.png"),
      pageNum: 1,
      pageSize: 15,
      listQuery: {
        informationId: "",
        title: "",
        stickType: "",
        categoryIdList: [],
        statusList: [],
        releaseCityIds: [],
        releaseTypeList: [],
        time: [],
      },
      TypeOptions,
      cities: [],
      props: {
        expandTrigger: "hover",
        multiple: true,
        label: "areaName",
        value: "areaId",
        children: "childrenAreas",
      },
      loading: false,
      tempRoute: {},
      uploadAction: process.env.BASE_URL + "/information/newInfo/upload",
      treeData: [],
      OrderProp: "",
      OrderType: "",
      baseUrl: "",
    };
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    },
  },
  created() {
    this.searchData();
    this.queryCityList();
    this.getGradeTree();
    this.getConfigMap();
  },
  activated() {
    this.searchData();
  },
  methods: {
    addSeting() {
      this.$router.push("/classification");
    },
    onCopy() {
      this.$message.success("资讯ID已复制！");
    },
    informationIdFormat(val) {
      let str = val.toString().substr(-4);
      return "..." + str;
    },
    getReadTooltip(row) {
      return (
        "基础值(" +
        row.readBasics +
        ")+实际用户操作值(" +
        row.userRead +
        ")*放大倍数(" +
        row.readMultiple +
        ")"
      );
    },
    getPointTooltip(row) {
      return (
        "基础值(" +
        row.pointBasics +
        ")+实际用户操作值(" +
        row.userPoint +
        ")*放大倍数(" +
        row.pointMultiple +
        ")"
      );
    },
    getConfigMap() {
      const opt = {
        url: "/acb/2.0/sysConfig/getConfigMap",
        method: "get",
        data: {},
        success: (res) => {
          this.baseUrl = res.value.appUrl;
        },
      };
      this.$request(opt);
    },
    // 操作细则
    handleCommand(command, data) {
      switch (command) {
        case "1":
          window.open(this.baseUrl + "/info.html?id=" + data.informationId);
          // if (this.baseUrl.indexOf("//app") > -1) {

          // } else {
          //   window.open(this.baseUrl + "/recommend/info.html?id=" + data.informationId);
          // }
          break;
        case "2":
          this.$router.push({ path: "infoDetail", query: { informationId: data.informationId } });
          break;
        case "3":
          this.upperShelf(data);
          break;
        case "4":
          this.lowerShelf(data.informationId);
          break;
        case "5":
          this.$router.push({ path: "infoEdit", query: { informationId: data.informationId } });
          break;
        case "6":
          this.deleteItem(data.informationId);
          break;
        case "7":
          this.sealUp(data.informationId);
          break;
        default:
          break;
      }
    },
    sealUp(informationId) {
      const opt = {
        url: "/acb/2.0/information/update",
        method: "post",
        data: { informationId, status: 5, updateUser: sessionStorage.userId },
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.$message.success("封存成功");
          this.searchData();
        },
      };
      this.$request(opt);
    },
    upperShelf(data) {
      if (data.status === 3) {
        let now = new Date().getTime();
        let lowerTime = new Date(data.lowerTime).getTime();
        if (now > lowerTime) {
          this.$alert("资讯已过期，请先修改下架时间");
          return;
        }
      }
      const opt = {
        url: "/acb/2.0/information/update",
        method: "post",
        data: { informationId: data.informationId, status: 2, updateUser: sessionStorage.userId },
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.$message.success("上架成功");
          this.searchData();
        },
      };
      this.$request(opt);
    },
    lowerShelf(informationId) {
      const opt = {
        url: "/acb/2.0/information/update",
        method: "post",
        data: { informationId, status: 3, updateUser: sessionStorage.userId },
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.$message.success("下架成功");
          this.searchData();
        },
      };
      this.$request(opt);
    },
    deleteItem(informationId) {
      const opt = {
        url: "/acb/2.0/information/update",
        method: "post",
        data: { informationId, status: 4, updateUser: sessionStorage.userId },
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.$message.success("删除成功");
          this.searchData();
        },
      };
      this.$request(opt);
    },
    handleChange(e) {
      if (e.length !== 0) {
        const arr = e.flat();
        this.parentIds = Array.from(new Set(arr)).join(",");
      } else {
        this.parentIds = "";
      }
    },
    // getCitys(e) {
    //   if (e.length !== 0) {
    //     this.citys = e.map(item => {
    //       return item[1]
    //     }).join(',')
    //   } else {
    //     this.citys = ''
    //   }
    // },
    getTime(e) {
      if (e) {
        [this.listQuery.createTime, this.listQuery.updateTime] = [...e];
      } else {
        this.listQuery.createTime = "";
        this.listQuery.updateTime = "";
      }
    },
    getGradeTree() {
      // 分类
      const opt = {
        url: "/acb/2.0/informationCategory/tree",
        method: "get",
        data: {},
        success: (res) => {
          this.treeData = res.value;
        },
      };
      this.$request(opt);
    },
    queryCityList() {
      const opt = {
        url: "/acb/2.0/area/provinceWithCity",
        method: "get",
        data: {},
        success: (res) => {
          this.cities = res.value;
        },
      };
      this.$request(opt);
    },
    handleModifyStatus(row, status) {
      this.$confirm(status === 2 ? "确定发布?" : "确定下架？", this.$t("pop_up.Tips"), {
        confirmButtonText: this.$t("pop_up.Determine"),
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const temp = Object.assign({}, row);
        temp.status = status;
        delete temp.updateTime;
        this.listLoading = true;
        // listQueryUpdate(temp)
        //   .then(res => {
        //     this.detailFormVisible = false
        //     if (res.data.resultCode === 200) {
        //       this.$notify.success('操作成功!')
        //       this.handleFilter()
        //     }
        //     this.listLoading = false
        //   })
        //   .catch(() => {
        //     this.listLoading = false
        //   })
      });
    },
    handleupStatus(row, status) {
      this.$confirm("确定发布?", this.$t("pop_up.Tips"), {
        confirmButtonText: this.$t("pop_up.Determine"),
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const temp = Object.assign({}, row);
        temp.status = status;
        delete temp.updateTime;
        this.listLoading = true;
        // upstatus(temp)
        //   .then(res => {
        //     this.detailFormVisible = false
        //     if (res.data.resultCode === 200) {
        //       this.$notify.success('操作成功!')
        //       this.handleFilter()
        //     }
        //     this.listLoading = false
        //   })
        //   .catch(() => {
        //     this.listLoading = false
        //   })
      });
    },
    numberF(val) {
      return parseInt(val);
    },
    handleCheck(row) {
      window.open(process.env.BASE_URL + "/html/information/info.html?id=" + row.id);
    },
    onAdd() {
      this.$router.push({ path: "infoEdit" });
    },
    onReset() {
      this.listQuery.informationId = "";
      this.listQuery.title = "";
      this.listQuery.stickType = "";
      this.listQuery.categoryIdList = [];
      this.listQuery.statusList = [];
      this.listQuery.releaseCityIds = [];
      this.listQuery.releaseTypeList = [];
      this.listQuery.time = [];
      this.searchData();
    },
    handleUpdate(row) {
      this.$router.push({ path: "infoEdit/" + row.id });
    },
    searchData() {
      const tempData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        informationId: this.listQuery.informationId,
        title: this.listQuery.title,
        stickType: this.listQuery.stickType,
        categoryIdList: _.uniq(_.flattenDeep(this.listQuery.categoryIdList)),
        statusList: this.listQuery.statusList,
        releaseCityIds: _.uniq(_.flattenDeep(this.listQuery.releaseCityIds)),
        releaseTypeList: _.uniq(_.flattenDeep(this.listQuery.releaseTypeList)),
        modifyStartTime: null,
        modifyEndTime: null,
      };
      if (this.listQuery.time && this.listQuery.time.length > 0) {
        tempData.modifyStartTime = this.listQuery.time[0];
        tempData.modifyEndTime = this.listQuery.time[1];
      }
      if (this.OrderProp) {
        tempData[this.OrderProp] = this.OrderType;
      }
      this.tableList = [];
      this.listLoading = true;
      const opt = {
        url: "/acb/2.0/information/list",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: tempData,
        success: (res) => {
          this.tableList = res.value.list;
          this.total = parseInt(res.value.total);
          this.listLoading = false;
        },
        fail: (err) => {
          this.listLoading = false;
        },
      };
      this.$request(opt);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    setCityName(data = []) {
      if (data.length > 0) {
        return data
          .map((item) => {
            return item.cityName;
          })
          .join(",");
      }
    },
    handleCreate() {
      this.$router.push({ path: "infoEdit" });
    },
    sortChange(data) {
      if (!data.order) {
        this.OrderProp = "";
        this.OrderType = "";
        this.searchData();
        return;
      }
      let prop = "";
      switch (data.prop) {
        case "read_number":
          prop = "readNumOrder";
          break;
        case "point_number":
          prop = "likeNumOrder";
          break;
        case "update_time":
          prop = "updateTimeOrder";
          break;
        default:
          break;
      }
      this.OrderProp = prop;
      this.OrderType = data.order === "descending" ? 1 : 0;
      this.searchData();
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  .FootBtn
    display block
    float left
    width 100%
    text-align center
    clear both
  .infoTop
    position absolute
    top 0
    left 0
  .poperStyle
    background transparent
  .createPost-main-container
    .article-textarea-content
      width 100%
      min-height 100px
    .center
      text-align center
      font-size 26px
    vertical-align top
    display inline-block
    padding 10px 20px
    .textCon
      color #ff0000
      .avatar-uploader
        .el-upload
          border 1px dashed #d9d9d9
          border-radius 6px
          cursor pointer
          position relative
          overflow hidden
          &:hover
            border-color #0f6eff
      .avatar-uploader-icon
        font-size 28px
        color #8c939d
        width 178px
        height 178px
        line-height 178px
        text-align center
      .avatar
        width 178px
        height 178px
        display block
    .postInfo-container
      position relative
      margin-bottom 10px
      .postInfo-container-item
        float left
  .word-counter
    width 40px
    position absolute
    right -10px
    top 0px
  .informationT
    width 35%
    height 160px
</style>
